export const Keywords: string[] = [
  "personaverse",
  "AI Chat App",
  "chatbot",
  "chat bot",
  "Conversational AI",
  "Chatbot App",
  "AI Persona Chat",
  "Immersive Chat Experience",
  "Personal AI",
  "AI Role Playing",
  "Advanced AI Chat",
  "Text-based AI",
  "Interactive AI",
  "openai",
  "open ai",
  "chatgpt",
  "chat gpt",
  "generative AI",
  "Personalized AI Chat",
  "AI Impersonation Chat",
  "Role-Play Chatbot",
  "AI Texting App",
  "Unique AI Chat Experience",
  "Text RPG with AI",
  "Persona-based AI",
  "AI Chat Simulator",
  "Fictional Character Chat",
  "AI-based Conversation App",
  "Dynamic AI Texting",
  "Customizable AI Chat",
  "Chat with AI Characters",
  "AI Chat Storytelling",
  "Advanced Text-based RPG",
  "AI Chat Adventure",
  "AI Role-Play Texting",
  "Realistic AI Chat Experience",
  "AI Character Interaction",
  "Conversational AI RPG",
];
